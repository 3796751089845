import React from 'react'
import { Bling as Ad } from 'react-gpt'
import classNames from 'classnames'

export function LeaderboardAd(props) {
  return (
    <div
      className={classNames('ad', 'leaderboard-ad')}
      style={{ ...props.style }}
    >
      <Ad
        adUnitPath="/21713899879/Live365_Web_728x90"
        slotSize={[728, 90]}
        collapseEmptyDiv={true}
        viewableThreshold={0}
      />
    </div>
  )
}

export function MediumRectangleAd(props) {
  return (
    <div
      className={classNames('ad', 'medium-rectangle-ad')}
      style={{ ...props.style }}
    >
      <Ad
        adUnitPath="/21713899879/Live365_Web_300x250"
        slotSize={[300, 250]}
        collapseEmptyDiv={true}
        viewableThreshold={0}
      />
    </div>
  )
}

export function LargeRectangleAd(props) {
  return (
    <div
      className={classNames('ad', 'large-rectangle-ad')}
      style={{ ...props.style }}
    >
      <Ad
        adUnitPath="/21713899879/Live365_Web_300x250_2"
        slotSize={[300, 600]}
        collapseEmptyDiv={true}
        viewableThreshold={0}
      />
    </div>
  )
}

export function MobileAd(props) {
  return (
    <div className={classNames('ad', 'mobile-ad')} style={{ ...props.style }}>
      <Ad
        adUnitPath="/21713899879/M_Live365_Web_300x250"
        slotSize={[300, 250]}
        collapseEmptyDiv={true}
        viewableThreshold={0}
      />
    </div>
  )
}
