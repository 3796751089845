// third-party
import React from 'react'
import { Link } from 'gatsby'
import { Bling as Ads } from 'react-gpt'
import Sticky from 'react-stickynode'

// internal
import {RowLayout, ColumnLayout} from '../../components/layout'
import PlayableStationLogo from '../../components/PlayableStationLogo'
import { LeaderboardAd, LargeRectangleAd, MobileAd } from '../../ads'

// assets
import './genre-page.css'

class GenrePage extends React.Component {
  componentDidMount() {
    this.adsRefreshIntervalID = setInterval(function() {
      Ads.refresh()
    }, 30000)
  }
  componentWillUnmount() {
    clearInterval(this.adsRefreshIntervalID)
  }
  render() {
    const data = this.props.data
    return (
      <div className="genre-page page-wrapper">

        <div className="content-section">
          <div className="content-section-foreground layout-wrapper">
            <RowLayout isResponsive breakPoint={1000} itemSpacing={'2rem'}>
              {typeof window !== 'undefined' &&
                window.innerWidth > 768 && (
                  <div
                    className="flex-box column"
                    style={{ minWidth: '300px' }}
                  >
                    <Sticky bottomBoundary=".content-section">
                      <LargeRectangleAd style={{ paddingTop: '1rem' }} />
                    </Sticky>
                  </div>
                )}
              <div
                className="flex-box column spacing-5_2"
              >
                {typeof window !== 'undefined' &&
                  window.innerWidth <= 768 && (
                    <MobileAd style={{ alignSelf: 'center' }} />
                  )}
                {data &&
                  data.fields.stationsData.length > 0 && (
                    <RowLayout isResponsive breakPoint={1000} className="featured-station" style={{ height: 'unset' }}>
                      <PlayableStationLogo
                        stationID={data.fields.stationsData[0].mount_id}
                        stationName={data.fields.stationsData[0].station_name}
                        stationLogoURL={data.fields.stationsData[0].logo}
                        stationURL={data.fields.stationsData[0].fields.url}
                        size={'196px'}
                      />
                      <div
                        className="flex-box column spacing-1"
                      >
                        <h2 className="heading">Featured Station</h2>
                        <Link
                          to={data.fields.stationsData[0].fields.url}
                          style={{ textDecoration: 'none', color: '#444' }}
                        >
                          <p className="featured-station-name">
                            {data.fields.stationsData[0].station_name}
                          </p>
                        </Link>
                        <p>{data.fields.stationsData[0].description}</p>
                      </div>
                    </RowLayout>
                  )}
                {typeof window !== 'undefined' &&
                  window.innerWidth > 1200 && <LeaderboardAd />}
                <div
                  className="flex-box column spacing-2_8"
                >
                  <h2 className="heading">Top {data && data.title} Stations</h2>
                  {data &&
                    data.fields.stationsData.length > 0 && (
                      <ul className="other-stations">
                        {data.fields.stationsData.slice(1).map((station, index) => (
                          <li key={index}>
                            <div className="other-station flex-box spacing-2">
                              <PlayableStationLogo
                                stationID={station.mount_id}
                                stationName={station.station_name}
                                stationLogoURL={station.logo}
                                stationURL={station.fields.url}
                                size={'96px'}
                              />
                            <ColumnLayout
                                itemSpacing={'1rem'}
                              >
                                <Link
                                  to={station.fields.url}
                                  style={{
                                    textDecoration: 'none',
                                    color: '#444'
                                  }}
                                >
                                  <p className="other-station-name">
                                    {station.station_name}
                                  </p>
                                </Link>
                              </ColumnLayout>
                            </div>
                          </li>
                        ))}
                      </ul>
                    )}
                </div>
              </div>
            </RowLayout>
          </div>
        </div>
      </div>
    )
  }
}

export default GenrePage
