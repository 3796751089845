import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

import SEO from '../components/SEO'
import Header from '../components/Header'
import Footer from '../components/Footer'

import GenrePageContent from '../old/pages/GenrePage'

// implemented as a class so lifecycle hooks can fetch dynamic data
export default class GenrePage extends React.Component {
  render() {
    if (!this.props.pageContext) {
      console.log('invalid page context:', this.props.pageContext)
      return null
    }
    const headerImage = this.props.data.headerImage.childImageSharp.fluid
    const { data: pageData } = this.props.pageContext
    return (
      <React.Fragment>
        <SEO
          title={`${pageData.title} - Free Internet Radio - Live365`}
          ignoreTitleTemplate={true}
          description={pageData.description}
          pathname={pageData.url}
          scripts={[{ src: '//biddr.brealtime.com/92516381-1202.js' }]}
        />
        <Header
          title={pageData.title}
          lightOnDark={true}
          background={{ image: headerImage }}
        >
          <Description>{pageData.description}</Description>
        </Header>
        <GenrePageContent data={pageData} />
        <Footer />
      </React.Fragment>
    )
  }
}

export const query = graphql`
  query($imageFilename: String) {
    headerImage: file(name: { eq: $imageFilename }) {
      childImageSharp {
        fluid(maxWidth: 3000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const Description = styled.h2`
  margin-bottom: 4rem;
  color: #fff;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.6;
  text-align: center;
`
